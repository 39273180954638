import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { Link } from 'gatsby';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';
import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import BlockLeft from '../containers/BlockLeft';
import BlockRight from '../containers/BlockRight';
import SEO from '../components/seo';
import { useTranslation, Trans } from 'react-i18next';

import Image01 from '../../src/images/security/1-encryption-in-general.jpg';
import Image02 from '../../src/images/security/2-your-browser-encryption.jpg';
import Image03 from '../../src/images/security/3-our-transport-encryption.jpg';
import Image04 from '../../src/images/security/4-the-encryption-at-rest.jpg';
import Image05 from '../../src/images/security/5-regular-security-updates.png';
import Image06 from '../../src/images/security/6-open-source-principle.jpg';
import Image07 from '../../src/images/security/7-service-availability.jpg';
import Image08 from '../../src/images/security/8-daily-backups-of-your-data.jpg';
import Image09 from '../../src/images/security/9-code-safety-through-code-audits.jpg';
import Image10 from '../../src/images/security/10-issue-minimization-automated-testing.jpg';
import Image11 from '../../src/images/security/11-automated-vulnerability-scans.jpg';
import Image12 from '../../src/images/security/12-multizone-network-design.jpg';
import Image13 from '../../src/images/security/13-advanced-ddos-protection.jpg';

export default ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Security')}
          lang={pageContext.lang}
          description={t(
            "Information about Psono's security approach: Encryption, high availability, daily backups, rights management, testing and compliance. Read more here!"
          )}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Security')}
            descriptionText={t('How we protect your passwords')}
          />

          <BlockLeft titleText={t('Encryption in general')} image={Image01}>
            <Trans i18nKey="encryption_general">
              Psono didn’t reinvent the wheel. In Psono’s core, we are using
              <b>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://en.wikipedia.org/wiki/Curve25519"
                >
                  Curve25519
                </a>
              </b>
              and
              <b>
                <a
                  target="_blank"
                  href="https://en.wikipedia.org/wiki/Salsa20"
                  rel="noreferrer"
                >
                  Salsa20
                </a>
              </b>
              in form of
              <a target="_blank" href="https://nacl.cr.yp.to/" rel="noreferrer">
                NaCl
              </a>
              (pronounced “salt”) the “Networking and Cryptography library”. Our
              server uses
              <a
                target="_blank"
                href="https://pynacl.readthedocs.io/en/latest/"
                rel="noreferrer"
              >
                PyNaCl
              </a>
              and our frontend
              <a
                target="_blank"
                href="https://github.com/3nsoft/ecma-nacl"
                rel="noreferrer"
              >
                ecma-nacl
              </a>
              . Both are well established implementations of NaCl. Why not
              <a
                target="_blank"
                rel="noreferrer"
                href="https://en.wikipedia.org/wiki/RSA_(cryptosystem)"
              >
                RSA
              </a>
              and
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard"
                rel="noreferrer"
              >
                AES
              </a>
              ? RSA and AES are far more famous but are easy to implement wrong
              and far slower (here is a useful
              <a
                target="_blank"
                href="https://blog.codecentric.de/en/2014/03/crypto-broken-apply-secure-crypto-developer/"
                rel="noreferrer"
              >
                article
              </a>
              that talks about differences and the “dangers” of using
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/RSA_(cryptosystem)"
                rel="noreferrer"
              >
                RSA
              </a>
              and
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard"
                rel="noreferrer"
              >
                AES
              </a>
              ).
            </Trans>
          </BlockLeft>

          <BlockRight titleText={t('Your Browser Encryption')} image={Image02}>
            <Trans i18nKey="browser_encryption">
              Psono encrypts all data (like passwords, or notes) with NaCl’s
              secret key authentication before leaving your browser and being
              stored on the server in a “secret object”. The key for the
              encryption is generated randomly and stored together with some
              metadata of your secret in your “datastore”. The browser
              application encrypts your “datastore” with a derivation of your
              password. As algorithm for this password derivation we are using
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Scrypt"
                rel="noreferrer"
              >
                <b>scrypt</b>
              </a>
              . Why not
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/PBKDF2"
                rel="noreferrer"
              >
                PBKDF2
              </a>
              ?
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/PBKDF2"
                rel="noreferrer"
              >
                PBKDF2
              </a>
              is far more famous but due to the lower resource demand
              (especially memory) “cheaply” implementable in hardware and easier
              to crack for an attacker.
            </Trans>
          </BlockRight>

          <BlockLeft titleText={t('Our Transport Encryption')} image={Image03}>
            <Trans i18nKey="transport_encryption">
              Three encryption layers protect your data on its way from your
              browser to our application server. The base layer is the
              encryption of the data itself done by the client. On top of that
              Psono has implemented a mid layer, which we usually refer to as
              Psono’s transport encryption layer. This layer is like a tunnel
              (similar to a
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Virtual_private_network"
                rel="noreferrer"
              >
                VPN tunnel
              </a>
              ) between the website (or extension) running inside your browser
              and our application server. In addition, on top as outer layer or
              shell layer we are using
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Transport_Layer_Security"
                rel="noreferrer"
              >
                HTTPS (in version TLS 1.2)
              </a>
              . Noteworthy is also, that both our mid and outer layer are built
              and configured to support
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Forward_secrecy"
                rel="noreferrer"
              >
                perfect forward security (PFS)
              </a>
              .
            </Trans>
          </BlockLeft>

          <BlockRight titleText={t('The Encryption at Rest')} image={Image04}>
            <Trans i18nKey="encryption_rest">
              The application server encrypts all private data (e.g. your email
              address), before storing it in the database. We are using here the
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Salsa20"
                rel="noreferrer"
              >
                Salsa20
              </a>
              stream cipher together with a
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Poly1305"
                rel="noreferrer"
              >
                Poly1305
              </a>{' '}
              with
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Message_authentication_code"
                rel="noreferrer"
              >
                MAC (message authentication code)
              </a>
              , reducing the necessary trust level of our
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Database_administrator"
                rel="noreferrer"
              >
                DBA (database administrator)
              </a>
              to a minimum.
            </Trans>
          </BlockRight>

          <BlockLeft titleText={t('Regular Security Updates')} image={Image05}>
            <Trans i18nKey="security_updates">
              You have to keep your software up to date. That is the best advice
              for any computer system and software. It’s the best protection
              against malware, viruses and hackers and a fundamental necessity
              to protect your secrets. Psono has understood this and releases
              regular updates with the newest security patches and features.
            </Trans>
          </BlockLeft>

          <BlockRight titleText={t('Open Source Principle')} image={Image06}>
            <Trans i18nKey="open_source">
              Psono’s core is open source and will stay open source. This means:
              No license fee. No over time increasing subscription costs. No
              hidden security flaws. Flexibility to move and host everywhere
              with the partner of your choice. Public auditability and security
              checks. You can fix a bug yourself if you want at any time.
            </Trans>
          </BlockRight>

          <BlockLeft titleText={t('Service Availability')} image={Image07}>
            <Trans i18nKey="service_availability">
              You need access to your passwords at all time. We understand that.
              Psono has three approaches to solve this problem. First, we offer
              everybody our server and clients for on-premise hosting, free of
              charge and with docker as easy as it gets. On our
              <Link to={`${pageContext.langPathPrefix}/download`}>
                download page
              </Link>
              , you can find the corresponding docker images for the
              <a
                target="_blank"
                href="https://hub.docker.com/r/psono/psono-server/"
                rel="noreferrer"
              >
                Psono Server
              </a>
              and
              <a
                target="_blank"
                rel="noreferrer"
                href="https://hub.docker.com/r/psono/psono-server/"
              >
                Psono Web Client
              </a>
              . Second, our easy text file backup function. Third, we are
              running in the cloud on Amazon’s
              <a
                target="_blank"
                href="https://aws.amazon.com/"
                rel="noreferrer"
              >
                AWS
              </a>
              and Google’s
              <a
                target="_blank"
                href="https://cloud.google.com/"
                rel="noreferrer"
              >
                GCP
              </a>
              minimizing the risks of datacenter outages, data loss and server
              problems.
            </Trans>
          </BlockLeft>

          <BlockRight
            titleText={t('Daily Backups of your data')}
            image={Image08}
          >
            <Trans i18nKey="daily_backups">
              We backup all data of the
              <a target="_blank" rel="noreferrer" href="https://psono.pw">
                Psono.pw Service
              </a>
              automatically every night to ensure the maximum possible
              protection against potential data loss. Access to those backups is
              only granted to a very limited amount of people.
            </Trans>
          </BlockRight>

          <BlockLeft
            titleText={t('Code safety through Code Audits')}
            image={Image09}
          >
            <Trans i18nKey="code_audits">
              We perform code audits on any new code that is added. The code
              requires manual approval before it’s put into production.
              Automatic checks ensure the quality of the code.
            </Trans>
          </BlockLeft>

          <BlockRight
            titleText={t('Issue minimization Automated Testing')}
            image={Image10}
          >
            <Trans i18nKey="automated_testing">
              We test all code automatically on multiple stages and it must pass
              several quality checks before it's considered to go live. You can
              find the current status of tests always as a badge here for
              <a
                target="_blank"
                href="https://gitlab.com/psono/psono-client"
                rel="noreferrer"
              >
                Psono Client
              </a>
              and the
              <a
                target="_blank"
                rel="noreferrer"
                href="https://gitlab.com/psono/psono-server"
              >
                Psono Server
              </a>
              .
            </Trans>
          </BlockRight>

          <BlockLeft
            titleText={t('Automated Vulnerability Scans')}
            image={Image11}
          >
            <Trans i18nKey="vulnerability_scans">
              We have multiple security and vulnerability scanners in place.
              This allows us to detect new threads and respond quickly.
              Automated scans run nightly and trigger notifications
              correspondingly. In addition, those scans are run automatically in
              our build pipeline, blocking vulnerable releases. Additional
              checks offered by third parties (e.g.
              <a
                href="https://github.com/"
                target="_blank"
                rel="noreferrer"
                data-original-title=""
                title=""
              >
                github.com
              </a>
              ) throw alerts for every new CVE.
            </Trans>
          </BlockLeft>

          <BlockRight titleText={t('Multizone Network Design')} image={Image12}>
            <Trans i18nKey="network_design">
              Our network is following best practices and uses the full power of
              security restrictions of our cloud providers. The network itself
              is split into multiple shells and firewalls in between to restrict
              access and prevent/delay possible intrusions.
            </Trans>
          </BlockRight>

          <BlockLeft titleText={t('Advanced DDoS Protection')} image={Image13}>
            <Trans i18nKey="ddos_protection">
              DDoS (Denial-of-Service) attacks are a big threat for current
              online services. That's why Psono has taken measures to protect
              itself and uses
              <a
                rel="noreferrer"
                href="https://www.cloudflare.com/"
                target="_blank"
              >
                Cloudflare
              </a>
              to mitigate the risk of outages due to DDoS attacks.
            </Trans>
          </BlockLeft>
          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
