import styled from 'styled-components';

const BlockRightWrapper = styled.section`
  
  padding: 30px 0 30px 0;
  
  .normal-text {
    font-family: 'Roboto', sans-serif;
    line-height: 2;
    color: #343d48cc;
    
    a {
    color: #343d48cc;
    text-decoration: underline;
    }
  }
  
  .info-sec-container {
    @media (min-width: 993px) {
      position: absolute;
      top: 50%;
      left: 52%;
      transform: translate(0%, -50%);
      z-index: -1;
    }
    @media (max-width: 768px) and (min-width: 768px) {
      top: 40%;
    }
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }

    .image_area {
      background-position: bottom left;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 50px 0 60px 0;
      @media (max-width: 767px) {
        padding-top: 0;
      }
    }
  }
`;

export default BlockRightWrapper;
